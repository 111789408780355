import axios from "axios";
import Cookies from "js-cookie";
import * as config from "../config";
import * as Sentry from "@sentry/vue";

interface RequestConfig {
  url?: string;
  method?: HttpMethod;
  baseURL?: string;
  headers?: any;
  params?: any;
  data?: any;
  timeout?: number;
  responseType?: ResponseType;
  withCredentials: boolean;
}

enum HttpMethod {
  GET = "GET",
  DELETE = "DELETE",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
}

export enum ResponseType {
  ArrayBuffer = "arraybuffer",
  Blob = "blob",
  Document = "document",
  Json = "json",
  Text = "text",
  Stream = "stream",
}

type IsSuccessfulResponse = (status: number) => boolean;

const isSuccessfulResponse: IsSuccessfulResponse = (status) => {
  return status >= 200 && status <= 299;
};

const getOrCreateSessionId = (): string => {
  // Check if we already have a session ID in storage
  let sessionId = sessionStorage.getItem("app_session_id");

  // If not, create a new one and store it
  if (!sessionId) {
    sessionId = Math.random().toString(36).substring(2, 15);
    sessionStorage.setItem("app_session_id", sessionId);
  }

  return sessionId;
};

const SESSION_ID = getOrCreateSessionId();

export default class HttpClient {
  private baseUrl: string;

  constructor() {
    this.baseUrl = config.viteApiBaseUrl;
  }

  private getAuthToken(): string | undefined {
    const token = Cookies.get("accessToken");
    return token;
  }

  private buildRequestOptions(
    url: string,
    method: HttpMethod = HttpMethod.GET,
  ): RequestConfig {
    const token = this.getAuthToken();
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
      "Client-Token": import.meta.env.VITE_CLIENT_TOKEN,
      "Client-Version": import.meta.env.VITE_APP_VERSION || "0.0.0",
      "x-session-id": SESSION_ID,
      "x-correlation-id": Math.random().toString(36).substring(2, 15),
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    return {
      method,
      headers,
      url: this.baseUrl + url,
      withCredentials: true,
    };
  }

  private async makeRequest(options: RequestConfig): Promise<any> {
    try {
      const response = await axios(options);

      // Check for minimum version requirement in response headers
      const minServerVersion = response.headers["x-min-server-version"];
      const clientVersion = import.meta.env.VITE_APP_VERSION || "0.0.0";

      if (
        minServerVersion &&
        !this.isVersionCompatible(minServerVersion, clientVersion)
      ) {
        this.handleVersionMismatch(minServerVersion, clientVersion);
      }

      Sentry.addBreadcrumb({
        category: "http",
        type: "http",
        data: {
          url: options.url,
          method: options.method,
          status: response.status,
          data: response.data,
        },
      });
      return response;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        try {
          const refreshResponse = await axios.post(
            `${this.baseUrl}/refreshToken`,
            {},
            {
              withCredentials: true, // Needed to include the httpOnly refreshToken cookie
            },
          );

          if (refreshResponse.status === 200) {
            const { accessToken } = refreshResponse.data;
            // Retry the original request with the new token
            options.headers.Authorization = `Bearer ${accessToken}`;
            return await axios(options);
          } else {
            // If token refresh fails, throw the original error
            throw error;
          }
        } catch (refreshError) {
          // If an error occurs during token refresh, log and throw the original error
          throw new Error("Not Authorized");
        }
      } else {
        // If the error is not due to a 401 status, log and throw it
        Sentry.captureException(error);
        throw error;
      }
    }
  }

  private isVersionCompatible(
    minServerVersion: string,
    clientVersion: string,
  ): boolean {
    const clientParts = clientVersion.split(".").map(Number);
    const serverParts = minServerVersion.split(".").map(Number);

    // Compare major version
    if (clientParts[0] !== serverParts[0]) {
      return clientParts[0] > serverParts[0];
    }

    // Compare minor version
    if (clientParts[1] !== serverParts[1]) {
      return clientParts[1] > serverParts[1];
    }

    // Compare patch version
    return clientParts[2] >= serverParts[2];
  }

  private handleVersionMismatch(
    minServerVersion: string,
    clientVersion: string,
  ): void {
    // Log the version mismatch
    console.warn(
      `Client version ${clientVersion} is below minimum required version ${minServerVersion}. Please update the application.`,
    );

    // Store the server version in localStorage to prevent infinite reloads
    const lastServerVersion = localStorage.getItem("last_server_version");
    if (lastServerVersion === minServerVersion) {
      return; // Already tried to reload for this version
    }

    localStorage.setItem("last_server_version", minServerVersion);

    // Show user-friendly message before reload
    if (
      confirm(
        "A new version of the application is required. The page will reload to update.",
      )
    ) {
      window.location.reload();
    }
  }

  public async get<T>(url: string, responseType?: ResponseType): Promise<T> {
    const options = this.buildRequestOptions(url);
    const response = await this.makeRequest({ ...options, responseType });

    if (!isSuccessfulResponse(response.status)) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.data;
  }

  public async post<T>(url: string, data?: any): Promise<T> {
    const options = this.buildRequestOptions(url, HttpMethod.POST);
    let headers = { ...options.headers };
    if (data instanceof FormData) {
      headers = {
        ...options.headers,
        "Content-Type": "multipart/form-data",
      };
    }
    const response = await this.makeRequest({ ...options, headers, data });

    if (!isSuccessfulResponse(response.status)) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.data;
  }

  public async put<T>(url: string, data: any): Promise<T> {
    const options = this.buildRequestOptions(url, HttpMethod.PUT);
    let headers = { ...options.headers };
    if (data instanceof FormData) {
      headers = {
        ...options.headers,
        "Content-Type": "multipart/form-data",
      };
    }
    const response = await this.makeRequest({ ...options, headers, data });

    if (!isSuccessfulResponse(response.status)) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.data;
  }

  public async delete(url: string, data?: any): Promise<void> {
    const options = this.buildRequestOptions(url, HttpMethod.DELETE);
    const response = await this.makeRequest({ ...options, data });

    if (!isSuccessfulResponse(response.status)) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  }
}
